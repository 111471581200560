<template>
  <div>
    <b-row>
      <b-col>
        <div class="cat-grid">
          <b-card
            v-if="
              userData.role_name === 'admin' ||
              userData.role_name === 'establishment_admin' ||
              userData.role_name === 'store_clerk'
            "
            @click="$router.push({ name: 'store-products' })"
            class="cat"
          >
            <div
              class="
                h-100
                d-flex
                flex-column
                justify-content-between
                align-items-center
              "
            >
              <div class="text-black text-center name">
                <feather-icon icon="EyeIcon" />
                Ver inventario
              </div>
              <feather-icon icon="FileTextIcon" class="icon" />
            </div>
          </b-card>

          <b-card
            v-if="
              userData.role_name === 'admin' ||
              userData.role_name === 'establishment_admin' ||
              userData.role_name === 'store_clerk'
            "
            @click="$router.push({ name: 'add-store-products' })"
            class="cat"
          >
            <div
              class="
                h-100
                d-flex
                flex-column
                justify-content-between
                align-items-center
              "
            >
              <div class="mb-2 text-black text-center name">
                <feather-icon icon="PlusIcon" />
                Agregar producto o servicio
              </div>
              <feather-icon icon="BoxIcon" class="icon" />
            </div>
          </b-card>

          <b-card
            v-if="
              userData.role_name === 'admin' ||
              userData.role_name === 'establishment_admin' ||
              userData.role_name === 'store_clerk'
            "
            @click="$router.push({ name: 'store-variants' })"
            class="cat"
          >
            <div
              class="
                h-100
                d-flex
                flex-column
                justify-content-between
                align-items-center
              "
            >
              <div class="text-black text-center name">
                <feather-icon icon="PlusIcon" />
                Agregar variantes
              </div>
              <feather-icon icon="ListIcon" class="icon" />
            </div>
          </b-card>

          <b-card
            v-if="
              userData.role_name === 'admin' ||
              userData.role_name === 'establishment_admin' ||
              userData.role_name === 'store_clerk'
            "
            @click="$router.push({ name: 'get-store-promotions' })"
            class="cat"
          >
            <div
              class="
                h-100
                d-flex
                flex-column
                justify-content-between
                align-items-center
              "
            >
              <div class="text-black text-center name">
                <feather-icon icon="EyeIcon" />
                Promos
              </div>
              <feather-icon icon="GiftIcon" class="icon" />
            </div>
          </b-card>

          <b-card
            v-if="
              userData.role_name === 'admin' ||
              userData.role_name === 'establishment_admin' ||
              userData.role_name === 'store_clerk'
            "
            @click="$router.push({ name: 'store-automatic-restocking' })"
            class="cat"
          >
            <div
              class="
                h-100
                d-flex
                flex-column
                justify-content-between
                align-items-center
              "
            >
              <div class="text-black text-center name">
                <feather-icon icon="PlusIcon" />
                Pedidos automáticos
              </div>
              <feather-icon icon="TruckIcon" class="icon" />
            </div>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "underscore"
import Ripple from "vue-ripple-directive"

export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/colors/palette-variables.scss";

.fixed-text {
  font-size: 13px !important;
  color: $primary !important;
}

.cat-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  grid-row: 1fr;
  font-weight: 600;
  font-size: 1rem;

  .cat {
    margin-bottom: 0;
    cursor: pointer;
    max-height: 160px;
    height: 160px;
  }

  @media (min-width: 350px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 700px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 1536px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (min-width: 1650px) {
    grid-template-columns: repeat(8, 1fr);
  }

  .icon {
    width: 50px;
    height: 50px;
  }
}
</style>
